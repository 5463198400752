import $ from 'jquery'

export function replaceWithPreserves(element, html) {
  const replacements = element.find('[data-preserve-during-replace]')
  const newElement = $(html)

  let restoreFocusOnElement =
    document.activeElement &&
    element[0]?.contains(document.activeElement) &&
    document.activeElement

  element.replaceWith(newElement)
  if (replacements.length > 0) {
    replacements.each((index, el) => {
      if (!el.id) {
        throw new Error(
          'You must provide an id on elements that have data-preserve-during-replace'
        )
      }
      newElement.find(`#${el.id}`).replaceWith(el)
    })
  }

  // If the element that was focused before was inside the element to be replaced, and is still present (was preserved), focus it again
  if (restoreFocusOnElement && newElement[0]?.contains(restoreFocusOnElement)) {
    restoreFocusOnElement.focus()
    restoreFocusOnElement.setSelectionRange?.(-1, -1)
  }

  $(newElement).changed?.()
}
